.GithubCodeContainer {
  height: 400vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.GithubCode {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  filter: sepia(3);
  mix-blend-mode: multiply;
  

  a {
    text-decoration: none;
    filter: brightness(0.5) contrast(2);
    opacity: 0.3;

    &:hover {
      filter: brightness(1);
      opacity: 1;
    }
  }
  
  pre {
    overflow: hidden !important;
    word-wrap: break-word !important;
    word-break: break-all !important;
    white-space: normal !important;
    padding: 0 !important;
    display: inline !important;
    tab-size: 2 !important;
    background: none !important;
    border: 0 !important;

    code {
      // color: rgb(95, 95, 95);
      // mix-blend-mode: multiply;
      word-wrap: break-word !important;
      word-break: break-all !important;
      white-space: normal !important;
      // line-height: 0;
      // z-index: 1000;
      background: none !important;
      border: 0 !important;
      font-size: 1.2rem !important;
    }
  }
}

.GithubCodeContainer-enter {
  opacity: 0 !important;
  filter: blur(40px);
}

.GithubCodeContainer-enter-active {
  opacity: 0.3 !important;
  filter: blur(0);
  transition: all 1000ms ease;
}

// #kanji-background {
//   position: absolute;
//   height: 100vh;
//   width: 100vw;
//   // left: 10vw;
//   // right: 10vw;
//   // top: 5vw;
//   mix-blend-mode: overlay;
//   filter: brightness(0) blur(0.3rem);
//   opacity: 0.15;
//   z-index: 0;
// }

// .GithubCodeContainer-exit {
//   opacity: 1 !important;
//   filter: blur(0);
// }

// .GithubCodeContainer-exit-active {
//   opacity: 0 !important;
//   filter: blur(40px);
//   transition: all 500ms ease;
// }
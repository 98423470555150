// Cinzel could also be 700, also used to have Old Standard TT
@import url('https://fonts.googleapis.com/css?family=Cinzel:400|Cormorant:300,400,400i,600|Ovo');
$canvas-scale: 0.5;

landscape-container {
  user-select: none;
}

.bottom-container {
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left bottom;
}

.rel-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.overflow-hidden {
  // clip-path: inset( -100vw -100vw -100vw 0 );
  overflow: hidden;
}

.landscape {
  position: absolute;
  left: 0;
  bottom: 0;
  user-select: none;
}

h2.landscape-name {
  display: block;
  font-family: 'Cormorant', serif;
  position: absolute;
  font-size: 50rem * $canvas-scale;
  z-index: 13;
  color: rgba(0, 0, 0, 0.274);
  mix-blend-mode: multiply;
  width: 100%;
  top: 4%;
  text-align: center;
  font-weight: 400;
  user-select: none;
}

.full-width {
  width: 100%;
}

#shining-effect {
  z-index: 1;
  opacity: 0.8;
  animation: shine 20s infinite ease;
  mix-blend-mode: screen;
  transform: scale(1.3);

  @keyframes shine {
    50% {
      opacity: 1;
      transform: scale(1.5);
      transform-origin: center bottom;
    }
  }
}

#sunrays {
  z-index: 2;
  animation: sunray-spin 120s infinite linear;
  opacity: 0.4;
  filter: blur(0.2vw);

  @keyframes sunray-spin {
    0%{
      transform: rotate(0deg) scale(1.8)
    }
    100% {
      transform: rotate(360deg) scale(1.8)

    }
  }
}

#jiri-head {
  mix-blend-mode: multiply;
  opacity: 0.68;
  z-index: 11;
}

#landscape-1 {
  z-index: 12;
}

.landscape-object {
  margin: 0;
  position: absolute;
  z-index: 13;
  transition: transform 1s ease, filter 0.5s ease;
  transform-origin: center bottom;
  user-select: none;

  &:hover {
    cursor: pointer;
    transition: filter 0.5s ease;
    filter: sepia(3);
    animation: boing 0.5s infinite ease-in-out;

    @keyframes boing {
      50% { transform: scale(1, 1.1) }
    }
  }
}

.book--tiny {
  border-radius: 10px * $canvas-scale;
  z-index: 15;
}

.book--tiny, .book--large {
  position: absolute;
  user-select: none;
}

.book--large {
  transition: all 0.3s ease;
  z-index: 20;

  &:hover {
    transform: translate(2rem * $canvas-scale, 2rem * $canvas-scale) scale(1.1);
    z-index: 21;
    filter: sepia(3);
    cursor: pointer;
  }

  &.book--large__zoomed {
    pointer-events: none;
    z-index: 28;
    cursor: normal;
  }
}

img.book--large__background {
  transition: all 0.4s ease;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 14;
  border-radius: 30px * $canvas-scale;
}

.book--large__title {
  font-family: 'Cormorant', serif;
  font-weight: 400;
  transition: all 0.4s ease;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  z-index: 15;
  white-space: nowrap;
  margin: 0;
  vertical-align: middle;
  pointer-events: none;

  span {
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
    margin: 0;

    color: transparent;
    background-clip: text;
    background-color: #2c2c2c;
    text-shadow: 0rem 1rem * $canvas-scale 1rem * $canvas-scale rgba(255, 255, 255, 0.192);

    &.dark-background {
      background-color: rgb(255, 255, 255);
      text-shadow: 0rem 1rem 0.6rem #3d3d3d63;
    }

    &.white-background {
      background-color: #3d3d3d;
      text-shadow: 0rem 1rem 1rem rgba(224, 224, 224, 0.26);
    }
  }
}

#book-stack--large {
  margin: 0;
  position: absolute;
  z-index: 13;
}

.landscape-variant-container {
  z-index: 13;
  transition: transform 1s ease;
}

.tooltip__black {
  background-image: url("../assets/box-dark-small.png");
  color: rgb(223, 223, 223);
}

.tooltip__white {
  background-image: url("../assets/box-light-small.png");
  opacity: 0.8;
  color: rgb(44, 44, 44);
}

.tooltip {
  font-family: 'Cormorant', serif;
  font-weight: 400;
  position: absolute;
  z-index: 14;
  border-radius: 10px;
  line-height: 0;
  background-size: 100% 100%;
  white-space: nowrap;
  user-select: none;
  pointer-events: none;
}

.tooltip-enter, .back-arrow-enter {
  opacity: 0 !important;
  filter: blur(40px);
}

.tooltip-enter-active, .back-arrow-enter-active {
  opacity: 1 !important;
  filter: blur(0);
  transition: all 500ms ease;
}

.tooltip-exit, .back-arrow-exit, .back-arrow-exit:hover {
  opacity: 1 !important;
  filter: blur(0);
}

.tooltip-exit-active, .back-arrow-exit-active, .back-arrow-exit-active:hover {
  opacity: 0 !important;
  filter: blur(40px);
  transition: all 500ms ease;
}

.landscape--1-enter, .landscape--2-enter {
  filter: blur(20px) !important;
  z-index: 14;
  opacity: 0;
  pointer-events: none;
}

.landscape--1-exit.landscape--1-exit-active, .landscape--2-exit.landscape--2-exit-active {
  filter: blur(20px) !important;
  opacity: 0;
  transition: all 1000ms ease-in-out;
}

div.landscape--1-exit.landscape--1-exit-active {
  transition: all 1000ms ease-in-out 200ms;
}

.landscape--1-exit, .landscape--2-exit {
  filter: blur(0) !important;
  opacity: 1;
}

.landscape--1-enter.landscape--1-enter-active, .landscape--2-enter.landscape--2-enter-active {
  filter: blur(0) !important;
  opacity: 1;
  transition: all 1000ms ease-in-out;
}

div.landscape--2-enter.landscape--2-enter-active {
  filter: blur(0) !important;
  opacity: 1;
  transition: all 1000ms ease-in-out 200ms;
}

.popup-window-background {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.301);
  z-index: 120;
  cursor: pointer;
}

.popup-window {
  background-image: url("../assets/box-light-small.png");
  background-size: 100% 100%;
  border-radius: 1rem;
  opacity: 0.8;
  margin: auto auto;
  z-index: 125;
  padding: 5vw;
  overflow-y: scroll;
  cursor: default;
  font-family: 'Cormorant', serif;
  font-weight: 300;
  transition: all 0.2s ease;
  font-size: 1rem;

  max-width: 80vw;
  max-height: calc(100vh - 20vw);
  @media (max-width: 1400px) {
    max-height: calc(100vh - 30vw);
  }

  &.popup-window-large {
    width: 80vw;
    height: calc(100vh - 20vw);

    @media (max-width: 1400px) {
      height: calc(100vh - 30vw);
    }
  }

  h1 {
    font-family: 'Cormorant', serif;
    font-weight: 600;
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  h2 {
    display: block;
    font-family: 'Cinzel', serif;
    font-weight: 400;
    font-size: 1.6rem;
  }

  h3 {
    font-family: 'Ovo', serif;
    font-size: 1.2rem;
  }

  p {
    font-size: 1.3rem;
    // strong {}
    // em {}
  }

  a.github-icon {
    float: right;
    opacity: 0.3;

    img {
      width: 4rem;
      transition: all 0.4s ease;
    }

    &:hover {
      filter: sepia(3);
      opacity: 1;
    }
  }

  img {
    width: 100%;
    border-radius: 1rem;
    &.icon {
      width: 3rem;
    }

    &.icon.technology-icon {
      width: 4rem;
      transition: all 0.4s ease;

      &:hover {
        transform: scale(1.2);
      }
    }

    &.icon-middle {
      opacity: 0.7;
    }

    &.icon-light {
      opacity: 0.3;
    }
  }

  a {
    text-decoration: none;
    color: rgb(87, 87, 87);
    filter: sepia(5);
    &:hover {
      color: rgb(233, 233, 233);
    }
    transition: all 0.2s ease;

    img.icon {
      transition: all 0.4s ease;

      &:hover {
        filter: brightness(0.1);
        transform: scale(1.3);
      }
    }
  }
  
  code {
    font-size: 1rem;
  }

  // ul {
  //   li {}
  // }

  // pre {}
  // blockquote {}
}

.popup-window-background-enter {
  opacity: 0;
  filter: blur(40px);
  & .popup-window-background-enter .popup-window {
    transform: scale(0, 0);
  }
}

.popup-window-background-enter-active {
  opacity: 1;
  filter: blur(0);
  transition: all 500ms ease 200ms;
  & .popup-window-background-enter-active .popup-window {
    transform: scale(1, 1);
  }
}

.popup-window-background-exit {
  opacity: 1;
  filter: blur(0);
  & .popup-window-background-exit .popup-window {
    transform: scale(1, 1);
  }
}

.popup-window-background-exit-active {
  opacity: 0;
  filter: blur(40px);
  transition: all 500ms ease;
  &  .popup-window-background-exit-active .popup-window {
    transform: scale(0, 0);
  }
}

#shadow-wrapper {
  filter: blur(10px);
  transform: translateY(-0.5rem);
}

svg {
  overflow: visible;
  position: absolute;

  &#book-stack-svg {
    stroke: none;
    z-index: 20;
  }

  path#book-stack-hitbox {
    z-index: 22;
    pointer-events: all;
  }

  path.shadow {
    z-index: 21;
    pointer-events: none;
    transform-origin: center bottom;
    mix-blend-mode: multiply;
  }

  path.book-stack-shadow--1 {
    transform: scaleY(-0.7) skew(-50deg);
    opacity: 0.5;
  }

  path.book-stack-shadow--2 {
    transform: scaleY(-1) skew(-45deg);
    opacity: 0.4;
  }
}

.back-arrow {
  position: fixed !important;
  width: 8vw;
  max-width: 8rem;
  left: 4vw;
  top: calc(50vh - 4vw);
  z-index: 30;

  transition: 0.4s all ease;
  opacity: 0.7;

  &:hover {
    &:not(.back-arrow-exit) {
      filter: sepia(3);
      cursor: pointer;
      animation: left-right 0.5s ease-in-out infinite alternate;
      opacity: 1;

      @keyframes left-right {
        to {transform: scale(0.9) translateX(-1rem)}
      }
    }
  }
}

.back-arrow-enter-active {
  transition: all 500ms ease 700ms !important;
}

.back-arrow-exit-active {
  transition: all 500ms ease !important;
}

.image-gallery-slides {
  height: 50vh;

  .image-gallery-slide {
    transition: all 1s ease !important;
    background: none;
    height: 100%;
    display: flex;

    .image-gallery-image {
      // margin: auto auto;
      // display: flex;

      img {
        max-height: 100%;
        max-width: 100%;
        width: unset !important;
        border-radius: 1rem;
        display: flex;
        margin: auto auto;
        // box-shadow: inset 30px 30px 10px #000000;
      }
    }

    &:not(.center) {
      opacity: 0;
      filter: blur(20px);
    }
  }
}

.image-gallery-left-nav, .image-gallery-right-nav {
  text-shadow: none;
  mix-blend-mode: multiply;

  &::before {
    text-shadow: none !important;
    color: rgb(199, 199, 199) !important;
  }

  &:hover::before {
    color: rgb(80, 80, 80) !important;
  }
}

#jiri-soul {
  height: max-content;
}

#jiri-soul__container {
  position: absolute;
  z-index: 13;
}

#jiri-soul__pupils {
  position: absolute;
  pointer-events: none;
  z-index: 14;
  transition: all 0.1s ease;
}

.creature {
  position: absolute;
  z-index: 12;
  user-select: none;
}

.air-creature {
  animation: fly 20s linear forwards;
  transform-origin: left top;

  @keyframes fly {
    0% {
      opacity: 0;
      transform: translateX(0) scale(0.8 * $canvas-scale);
    }
    10% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      transform: translateX(4000px * $canvas-scale) scale(0.8 * $canvas-scale);
      opacity: 0;
    }
  }
}

.ground-creature {
  animation: walk 10s linear forwards;
  transform-origin: left top;

  @keyframes walk {
    0% {
      opacity: 0;
      transform: translateX(0) scale(0.5 * $canvas-scale);
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: translateX(500px * $canvas-scale) scale(0.5 * $canvas-scale);
      opacity: 0;
    }
  }
}

.tech-cloud-container {
  position: absolute;
  width:  100px * $canvas-scale;
  height: 100px * $canvas-scale;
  animation: fly-up 10s linear forwards;
  pointer-events: none;
  
  /// This animation destroys something: if there's a moving cloud in existance (/display?) (opacity doesn't seem to matter) the background clips away on zooms for smaller objects
  @keyframes fly-up {
    0% {
      opacity: 0;
      transform: translateY(50px * $canvas-scale) scale(0);
    }
    20% {
      opacity: 1;
      transform: translateY(-100px * $canvas-scale) scale(1);
    }
    80% {
      opacity: 1;
    }
    100% {
      transform: translateY(-500px * $canvas-scale);
      opacity: 0;
    }
  }
}

.tech-cloud__cloud {
  position: absolute;
  width: 100%;
  z-index: 14;
}

.tech-cloud__icon {
  position: absolute;
  width: 100%;
  z-index: 15;
  opacity: 0.5;
}
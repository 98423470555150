@import url('https://fonts.googleapis.com/css?family=Cinzel:400');
// @font-face {
//   font-family: 'Aozora Mincho';
//   src: url('../assets/fonts/AozoraMincho-thin.ttf');
// }

$text-shadow-spread: 0.03;
$text-shadow-opacity: 0;
$text-shadow-plus: 0.6;

nav {
  position: fixed;
  top: 0;
  padding: 2vh 0 4vh 0;
  background: linear-gradient(rgba(0, 0, 0, 0.644), rgba(0, 0, 0, 0.01) 75%, rgba(0, 0, 0, 0));
  background-blend-mode: multiply;
  width: 100%;
  height: 6vh;
  z-index: 100;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

li.nav-item {
    margin: 0 0;
    font-size: 2.3vh;
    color: rgb(223, 223, 223);
    padding: 0 2.4vh;
    font-family: 'Aozora Mincho';
    mix-blend-mode: screen;
    z-index: 101;
    pointer-events: all;
    user-select: none;
    
    display: flex;
    flex: auto;
    flex-grow: 1; 
    justify-content: center;
    transition: filter 0.4s ease, all 0.2s ease;

    &:hover {
      cursor: pointer;
      filter: brightness(1.5);
      animation: glow 0.8s ease-in-out infinite alternate;

      // Animation lightly based on https://www.w3schools.com/howto/howto_css_glowing_text.asp
      @keyframes glow {
        to {
          $new-text-shadow-opacity: $text-shadow-opacity + $text-shadow-plus;
          text-shadow: 
            0 0 2rem * $text-shadow-spread #ffffff * ($new-text-shadow-opacity),
            0 0 3rem * $text-shadow-spread #cfcfcf * ($new-text-shadow-opacity),
            0 0 4rem * $text-shadow-spread #cfcfcf * ($new-text-shadow-opacity),
            0 0 5rem * $text-shadow-spread #cfcfcf * ($new-text-shadow-opacity),
            0 0 6rem * $text-shadow-spread #cfcfcf * ($new-text-shadow-opacity),
            0 0 7rem * $text-shadow-spread #cfcfcf * ($new-text-shadow-opacity),
            0 0 8rem * $text-shadow-spread #cfcfcf * ($new-text-shadow-opacity);
        }
      }
    }

    &#center-name {
      font-size: 4vh;
      margin-top: 3vh;
      overflow: visible;
      font-weight: 100;
      flex-grow: 10;
      font-family: 'Aozora Mincho';
      white-space: nowrap;
      word-wrap: break-word !important;
      word-break: break-all !important;
      z-index: 110;

      display: flex;
      flex-direction: column;
      text-align: center;

      p {
        margin: 0;
      }

      p.subtitle {
        display: block;
        background: none;
        color: rgb(223, 223, 223);
        font-family: 'Cinzel', serif;
        font-size: 1vh;
        margin: 0;
        line-height: 1.5vh;
      }
    }
  }

.nav-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0.9;
  background-color: rgb(0, 0, 0);
  z-index: 110;
  text-align: center;
  list-style-type: none;
  transition: all 0.4s ease;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    opacity: 0.95
  }

  #nav-overlay-x {
    font-size: 2rem;
    margin: 1rem auto 0 auto;
    flex-grow: 10;
  }

  #nav-filler-bottom {
    flex-grow: 10;
    padding: 0.7rem;
  }

  li {
    flex-grow: unset;
    margin: 0;
  }

  .nav-item {
    font-size: 2rem;
    padding: 1.5rem;
  }

  li.sub-nav-item {
    font-size: 1.3rem;
    padding-top: 0.4rem;
    padding-bottom: 0.8rem;
    opacity: 0.7;
  }
}

.nav-overlay-enter {
  opacity: 0 !important;
  filter: blur(200px);
}

.nav-overlay-enter-active {
  opacity: 1 !important;
  filter: blur(0);
  transition: all 400ms ease;
}

.nav-overlay-exit {
  opacity: 1 !important;
  filter: blur(0);
}

.nav-overlay-exit-active {
  opacity: 0 !important;
  filter: blur(200px);
  transition: all 400ms ease;
}
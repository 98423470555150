@import url(https://fonts.googleapis.com/css?family=Cinzel:400);
@import url(https://fonts.googleapis.com/css?family=Cinzel:400|Cormorant:300,400,400i,600|Ovo);
@import url(https://fonts.googleapis.com/css?family=Cinzel:700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  position: fixed;
  top: 0;
  padding: 2vh 0 4vh 0;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.644), rgba(0, 0, 0, 0.01) 75%, rgba(0, 0, 0, 0));
  background: linear-gradient(rgba(0, 0, 0, 0.644), rgba(0, 0, 0, 0.01) 75%, rgba(0, 0, 0, 0));
  background-blend-mode: multiply;
  width: 100%;
  height: 6vh;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }

li.nav-item {
  margin: 0 0;
  font-size: 2.3vh;
  color: #dfdfdf;
  padding: 0 2.4vh;
  font-family: 'Aozora Mincho';
  mix-blend-mode: screen;
  z-index: 101;
  pointer-events: all;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  flex: auto;
  flex-grow: 1;
  justify-content: center;
  -webkit-transition: all 0.2s ease, -webkit-filter 0.4s ease;
  transition: all 0.2s ease, -webkit-filter 0.4s ease;
  transition: filter 0.4s ease, all 0.2s ease;
  transition: filter 0.4s ease, all 0.2s ease, -webkit-filter 0.4s ease; }
  li.nav-item:hover {
    cursor: pointer;
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    -webkit-animation: glow 0.8s ease-in-out infinite alternate;
            animation: glow 0.8s ease-in-out infinite alternate; }

@-webkit-keyframes glow {
  to {
    text-shadow: 0 0 0.06rem #999999, 0 0 0.09rem #7c7c7c, 0 0 0.12rem #7c7c7c, 0 0 0.15rem #7c7c7c, 0 0 0.18rem #7c7c7c, 0 0 0.21rem #7c7c7c, 0 0 0.24rem #7c7c7c; } }

@keyframes glow {
  to {
    text-shadow: 0 0 0.06rem #999999, 0 0 0.09rem #7c7c7c, 0 0 0.12rem #7c7c7c, 0 0 0.15rem #7c7c7c, 0 0 0.18rem #7c7c7c, 0 0 0.21rem #7c7c7c, 0 0 0.24rem #7c7c7c; } }
  li.nav-item#center-name {
    font-size: 4vh;
    margin-top: 3vh;
    overflow: visible;
    font-weight: 100;
    flex-grow: 10;
    font-family: 'Aozora Mincho';
    white-space: nowrap;
    word-wrap: break-word !important;
    word-break: break-all !important;
    z-index: 110;
    display: flex;
    flex-direction: column;
    text-align: center; }
    li.nav-item#center-name p {
      margin: 0; }
    li.nav-item#center-name p.subtitle {
      display: block;
      background: none;
      color: #dfdfdf;
      font-family: 'Cinzel', serif;
      font-size: 1vh;
      margin: 0;
      line-height: 1.5vh; }

.nav-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0.9;
  background-color: black;
  z-index: 110;
  text-align: center;
  list-style-type: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .nav-overlay:hover {
    opacity: 0.95; }
  .nav-overlay #nav-overlay-x {
    font-size: 2rem;
    margin: 1rem auto 0 auto;
    flex-grow: 10; }
  .nav-overlay #nav-filler-bottom {
    flex-grow: 10;
    padding: 0.7rem; }
  .nav-overlay li {
    flex-grow: unset;
    margin: 0; }
  .nav-overlay .nav-item {
    font-size: 2rem;
    padding: 1.5rem; }
  .nav-overlay li.sub-nav-item {
    font-size: 1.3rem;
    padding-top: 0.4rem;
    padding-bottom: 0.8rem;
    opacity: 0.7; }

.nav-overlay-enter {
  opacity: 0 !important;
  -webkit-filter: blur(200px);
          filter: blur(200px); }

.nav-overlay-enter-active {
  opacity: 1 !important;
  -webkit-filter: blur(0);
          filter: blur(0);
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease; }

.nav-overlay-exit {
  opacity: 1 !important;
  -webkit-filter: blur(0);
          filter: blur(0); }

.nav-overlay-exit-active {
  opacity: 0 !important;
  -webkit-filter: blur(200px);
          filter: blur(200px);
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease; }

.GithubCodeContainer {
  height: 400vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  z-index: 1; }

.GithubCode {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  -webkit-filter: sepia(3);
          filter: sepia(3);
  mix-blend-mode: multiply; }
  .GithubCode a {
    text-decoration: none;
    -webkit-filter: brightness(0.5) contrast(2);
            filter: brightness(0.5) contrast(2);
    opacity: 0.3; }
    .GithubCode a:hover {
      -webkit-filter: brightness(1);
              filter: brightness(1);
      opacity: 1; }
  .GithubCode pre {
    overflow: hidden !important;
    word-wrap: break-word !important;
    word-break: break-all !important;
    white-space: normal !important;
    padding: 0 !important;
    display: inline !important;
    -moz-tab-size: 2 !important;
         tab-size: 2 !important;
    background: none !important;
    border: 0 !important; }
    .GithubCode pre code {
      word-wrap: break-word !important;
      word-break: break-all !important;
      white-space: normal !important;
      background: none !important;
      border: 0 !important;
      font-size: 1.2rem !important; }

.GithubCodeContainer-enter {
  opacity: 0 !important;
  -webkit-filter: blur(40px);
          filter: blur(40px); }

.GithubCodeContainer-enter-active {
  opacity: 0.3 !important;
  -webkit-filter: blur(0);
          filter: blur(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease; }

landscape-container {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.bottom-container {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom; }

.rel-container {
  position: relative;
  height: 100%;
  width: 100%; }

.overflow-hidden {
  overflow: hidden; }

.landscape {
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

h2.landscape-name {
  display: block;
  font-family: 'Cormorant', serif;
  position: absolute;
  font-size: 25rem;
  z-index: 13;
  color: rgba(0, 0, 0, 0.274);
  mix-blend-mode: multiply;
  width: 100%;
  top: 4%;
  text-align: center;
  font-weight: 400;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.full-width {
  width: 100%; }

#shining-effect {
  z-index: 1;
  opacity: 0.8;
  -webkit-animation: shine 20s infinite ease;
          animation: shine 20s infinite ease;
  mix-blend-mode: screen;
  -webkit-transform: scale(1.3);
          transform: scale(1.3); }

@-webkit-keyframes shine {
  50% {
    opacity: 1;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom; } }

@keyframes shine {
  50% {
    opacity: 1;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom; } }

#sunrays {
  z-index: 2;
  -webkit-animation: sunray-spin 120s infinite linear;
          animation: sunray-spin 120s infinite linear;
  opacity: 0.4;
  -webkit-filter: blur(0.2vw);
          filter: blur(0.2vw); }

@-webkit-keyframes sunray-spin {
  0% {
    -webkit-transform: rotate(0deg) scale(1.8);
            transform: rotate(0deg) scale(1.8); }
  100% {
    -webkit-transform: rotate(360deg) scale(1.8);
            transform: rotate(360deg) scale(1.8); } }

@keyframes sunray-spin {
  0% {
    -webkit-transform: rotate(0deg) scale(1.8);
            transform: rotate(0deg) scale(1.8); }
  100% {
    -webkit-transform: rotate(360deg) scale(1.8);
            transform: rotate(360deg) scale(1.8); } }

#jiri-head {
  mix-blend-mode: multiply;
  opacity: 0.68;
  z-index: 11; }

#landscape-1 {
  z-index: 12; }

.landscape-object {
  margin: 0;
  position: absolute;
  z-index: 13;
  -webkit-transition: -webkit-transform 1s ease, -webkit-filter 0.5s ease;
  transition: -webkit-transform 1s ease, -webkit-filter 0.5s ease;
  transition: transform 1s ease, filter 0.5s ease;
  transition: transform 1s ease, filter 0.5s ease, -webkit-transform 1s ease, -webkit-filter 0.5s ease;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .landscape-object:hover {
    cursor: pointer;
    -webkit-transition: -webkit-filter 0.5s ease;
    transition: -webkit-filter 0.5s ease;
    transition: filter 0.5s ease;
    transition: filter 0.5s ease, -webkit-filter 0.5s ease;
    -webkit-filter: sepia(3);
            filter: sepia(3);
    -webkit-animation: boing 0.5s infinite ease-in-out;
            animation: boing 0.5s infinite ease-in-out; }

@-webkit-keyframes boing {
  50% {
    -webkit-transform: scale(1, 1.1);
            transform: scale(1, 1.1); } }

@keyframes boing {
  50% {
    -webkit-transform: scale(1, 1.1);
            transform: scale(1, 1.1); } }

.book--tiny {
  border-radius: 5px;
  z-index: 15; }

.book--tiny, .book--large {
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.book--large {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 20; }
  .book--large:hover {
    -webkit-transform: translate(1rem, 1rem) scale(1.1);
            transform: translate(1rem, 1rem) scale(1.1);
    z-index: 21;
    -webkit-filter: sepia(3);
            filter: sepia(3);
    cursor: pointer; }
  .book--large.book--large__zoomed {
    pointer-events: none;
    z-index: 28;
    cursor: normal; }

img.book--large__background {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 14;
  border-radius: 15px; }

.book--large__title {
  font-family: 'Cormorant', serif;
  font-weight: 400;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  z-index: 15;
  white-space: nowrap;
  margin: 0;
  vertical-align: middle;
  pointer-events: none; }
  .book--large__title span {
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    color: transparent;
    -webkit-background-clip: text;
            background-clip: text;
    background-color: #2c2c2c;
    text-shadow: 0rem 0.5rem 0.5rem rgba(255, 255, 255, 0.192); }
    .book--large__title span.dark-background {
      background-color: white;
      text-shadow: 0rem 1rem 0.6rem #3d3d3d63; }
    .book--large__title span.white-background {
      background-color: #3d3d3d;
      text-shadow: 0rem 1rem 1rem rgba(224, 224, 224, 0.26); }

#book-stack--large {
  margin: 0;
  position: absolute;
  z-index: 13; }

.landscape-variant-container {
  z-index: 13;
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease; }

.tooltip__black {
  background-image: url(/static/media/box-dark-small.93b124c4.png);
  color: #dfdfdf; }

.tooltip__white {
  background-image: url(/static/media/box-light-small.6d431668.png);
  opacity: 0.8;
  color: #2c2c2c; }

.tooltip {
  font-family: 'Cormorant', serif;
  font-weight: 400;
  position: absolute;
  z-index: 14;
  border-radius: 10px;
  line-height: 0;
  background-size: 100% 100%;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none; }

.tooltip-enter, .back-arrow-enter {
  opacity: 0 !important;
  -webkit-filter: blur(40px);
          filter: blur(40px); }

.tooltip-enter-active, .back-arrow-enter-active {
  opacity: 1 !important;
  -webkit-filter: blur(0);
          filter: blur(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease; }

.tooltip-exit, .back-arrow-exit, .back-arrow-exit:hover {
  opacity: 1 !important;
  -webkit-filter: blur(0);
          filter: blur(0); }

.tooltip-exit-active, .back-arrow-exit-active, .back-arrow-exit-active:hover {
  opacity: 0 !important;
  -webkit-filter: blur(40px);
          filter: blur(40px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease; }

.landscape--1-enter, .landscape--2-enter {
  -webkit-filter: blur(20px) !important;
          filter: blur(20px) !important;
  z-index: 14;
  opacity: 0;
  pointer-events: none; }

.landscape--1-exit.landscape--1-exit-active, .landscape--2-exit.landscape--2-exit-active {
  -webkit-filter: blur(20px) !important;
          filter: blur(20px) !important;
  opacity: 0;
  -webkit-transition: all 1000ms ease-in-out;
  transition: all 1000ms ease-in-out; }

div.landscape--1-exit.landscape--1-exit-active {
  -webkit-transition: all 1000ms ease-in-out 200ms;
  transition: all 1000ms ease-in-out 200ms; }

.landscape--1-exit, .landscape--2-exit {
  -webkit-filter: blur(0) !important;
          filter: blur(0) !important;
  opacity: 1; }

.landscape--1-enter.landscape--1-enter-active, .landscape--2-enter.landscape--2-enter-active {
  -webkit-filter: blur(0) !important;
          filter: blur(0) !important;
  opacity: 1;
  -webkit-transition: all 1000ms ease-in-out;
  transition: all 1000ms ease-in-out; }

div.landscape--2-enter.landscape--2-enter-active {
  -webkit-filter: blur(0) !important;
          filter: blur(0) !important;
  opacity: 1;
  -webkit-transition: all 1000ms ease-in-out 200ms;
  transition: all 1000ms ease-in-out 200ms; }

.popup-window-background {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.301);
  z-index: 120;
  cursor: pointer; }

.popup-window {
  background-image: url(/static/media/box-light-small.6d431668.png);
  background-size: 100% 100%;
  border-radius: 1rem;
  opacity: 0.8;
  margin: auto auto;
  z-index: 125;
  padding: 5vw;
  overflow-y: scroll;
  cursor: default;
  font-family: 'Cormorant', serif;
  font-weight: 300;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-size: 1rem;
  max-width: 80vw;
  max-height: calc(100vh - 20vw); }
  @media (max-width: 1400px) {
    .popup-window {
      max-height: calc(100vh - 30vw); } }
  .popup-window.popup-window-large {
    width: 80vw;
    height: calc(100vh - 20vw); }
    @media (max-width: 1400px) {
      .popup-window.popup-window-large {
        height: calc(100vh - 30vw); } }
  .popup-window h1 {
    font-family: 'Cormorant', serif;
    font-weight: 600;
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 2rem;
    margin-top: 0; }
  .popup-window h2 {
    display: block;
    font-family: 'Cinzel', serif;
    font-weight: 400;
    font-size: 1.6rem; }
  .popup-window h3 {
    font-family: 'Ovo', serif;
    font-size: 1.2rem; }
  .popup-window p {
    font-size: 1.3rem; }
  .popup-window a.github-icon {
    float: right;
    opacity: 0.3; }
    .popup-window a.github-icon img {
      width: 4rem;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease; }
    .popup-window a.github-icon:hover {
      -webkit-filter: sepia(3);
              filter: sepia(3);
      opacity: 1; }
  .popup-window img {
    width: 100%;
    border-radius: 1rem; }
    .popup-window img.icon {
      width: 3rem; }
    .popup-window img.icon.technology-icon {
      width: 4rem;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease; }
      .popup-window img.icon.technology-icon:hover {
        -webkit-transform: scale(1.2);
                transform: scale(1.2); }
    .popup-window img.icon-middle {
      opacity: 0.7; }
    .popup-window img.icon-light {
      opacity: 0.3; }
  .popup-window a {
    text-decoration: none;
    color: #575757;
    -webkit-filter: sepia(5);
            filter: sepia(5);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
    .popup-window a:hover {
      color: #e9e9e9; }
    .popup-window a img.icon {
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease; }
      .popup-window a img.icon:hover {
        -webkit-filter: brightness(0.1);
                filter: brightness(0.1);
        -webkit-transform: scale(1.3);
                transform: scale(1.3); }
  .popup-window code {
    font-size: 1rem; }

.popup-window-background-enter {
  opacity: 0;
  -webkit-filter: blur(40px);
          filter: blur(40px); }
  .popup-window-background-enter .popup-window-background-enter .popup-window {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }

.popup-window-background-enter-active {
  opacity: 1;
  -webkit-filter: blur(0);
          filter: blur(0);
  -webkit-transition: all 500ms ease 200ms;
  transition: all 500ms ease 200ms; }
  .popup-window-background-enter-active .popup-window-background-enter-active .popup-window {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }

.popup-window-background-exit {
  opacity: 1;
  -webkit-filter: blur(0);
          filter: blur(0); }
  .popup-window-background-exit .popup-window-background-exit .popup-window {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }

.popup-window-background-exit-active {
  opacity: 0;
  -webkit-filter: blur(40px);
          filter: blur(40px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease; }
  .popup-window-background-exit-active .popup-window-background-exit-active .popup-window {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }

#shadow-wrapper {
  -webkit-filter: blur(10px);
          filter: blur(10px);
  -webkit-transform: translateY(-0.5rem);
          transform: translateY(-0.5rem); }

svg {
  overflow: visible;
  position: absolute; }
  svg#book-stack-svg {
    stroke: none;
    z-index: 20; }
  svg path#book-stack-hitbox {
    z-index: 22;
    pointer-events: all; }
  svg path.shadow {
    z-index: 21;
    pointer-events: none;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    mix-blend-mode: multiply; }
  svg path.book-stack-shadow--1 {
    -webkit-transform: scaleY(-0.7) skew(-50deg);
            transform: scaleY(-0.7) skew(-50deg);
    opacity: 0.5; }
  svg path.book-stack-shadow--2 {
    -webkit-transform: scaleY(-1) skew(-45deg);
            transform: scaleY(-1) skew(-45deg);
    opacity: 0.4; }

.back-arrow {
  position: fixed !important;
  width: 8vw;
  max-width: 8rem;
  left: 4vw;
  top: calc(50vh - 4vw);
  z-index: 30;
  -webkit-transition: 0.4s all ease;
  transition: 0.4s all ease;
  opacity: 0.7; }
  .back-arrow:hover:not(.back-arrow-exit) {
    -webkit-filter: sepia(3);
            filter: sepia(3);
    cursor: pointer;
    -webkit-animation: left-right 0.5s ease-in-out infinite alternate;
            animation: left-right 0.5s ease-in-out infinite alternate;
    opacity: 1; }

@-webkit-keyframes left-right {
  to {
    -webkit-transform: scale(0.9) translateX(-1rem);
            transform: scale(0.9) translateX(-1rem); } }

@keyframes left-right {
  to {
    -webkit-transform: scale(0.9) translateX(-1rem);
            transform: scale(0.9) translateX(-1rem); } }

.back-arrow-enter-active {
  -webkit-transition: all 500ms ease 700ms !important;
  transition: all 500ms ease 700ms !important; }

.back-arrow-exit-active {
  -webkit-transition: all 500ms ease !important;
  transition: all 500ms ease !important; }

.image-gallery-slides {
  height: 50vh; }
  .image-gallery-slides .image-gallery-slide {
    -webkit-transition: all 1s ease !important;
    transition: all 1s ease !important;
    background: none;
    height: 100%;
    display: flex; }
    .image-gallery-slides .image-gallery-slide .image-gallery-image img {
      max-height: 100%;
      max-width: 100%;
      width: unset !important;
      border-radius: 1rem;
      display: flex;
      margin: auto auto; }
    .image-gallery-slides .image-gallery-slide:not(.center) {
      opacity: 0;
      -webkit-filter: blur(20px);
              filter: blur(20px); }

.image-gallery-left-nav, .image-gallery-right-nav {
  text-shadow: none;
  mix-blend-mode: multiply; }
  .image-gallery-left-nav::before, .image-gallery-right-nav::before {
    text-shadow: none !important;
    color: #c7c7c7 !important; }
  .image-gallery-left-nav:hover::before, .image-gallery-right-nav:hover::before {
    color: #505050 !important; }

#jiri-soul {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content; }

#jiri-soul__container {
  position: absolute;
  z-index: 13; }

#jiri-soul__pupils {
  position: absolute;
  pointer-events: none;
  z-index: 14;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease; }

.creature {
  position: absolute;
  z-index: 12;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.air-creature {
  -webkit-animation: fly 20s linear forwards;
          animation: fly 20s linear forwards;
  -webkit-transform-origin: left top;
          transform-origin: left top; }

@-webkit-keyframes fly {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0) scale(0.4);
            transform: translateX(0) scale(0.4); }
  10% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    -webkit-transform: translateX(2000px) scale(0.4);
            transform: translateX(2000px) scale(0.4);
    opacity: 0; } }

@keyframes fly {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0) scale(0.4);
            transform: translateX(0) scale(0.4); }
  10% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    -webkit-transform: translateX(2000px) scale(0.4);
            transform: translateX(2000px) scale(0.4);
    opacity: 0; } }

.ground-creature {
  -webkit-animation: walk 10s linear forwards;
          animation: walk 10s linear forwards;
  -webkit-transform-origin: left top;
          transform-origin: left top; }

@-webkit-keyframes walk {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0) scale(0.25);
            transform: translateX(0) scale(0.25); }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    -webkit-transform: translateX(250px) scale(0.25);
            transform: translateX(250px) scale(0.25);
    opacity: 0; } }

@keyframes walk {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0) scale(0.25);
            transform: translateX(0) scale(0.25); }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    -webkit-transform: translateX(250px) scale(0.25);
            transform: translateX(250px) scale(0.25);
    opacity: 0; } }

.tech-cloud-container {
  position: absolute;
  width: 50px;
  height: 50px;
  -webkit-animation: fly-up 10s linear forwards;
          animation: fly-up 10s linear forwards;
  pointer-events: none; }

@-webkit-keyframes fly-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(25px) scale(0);
            transform: translateY(25px) scale(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-50px) scale(1);
            transform: translateY(-50px) scale(1); }
  80% {
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-250px);
            transform: translateY(-250px);
    opacity: 0; } }

@keyframes fly-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(25px) scale(0);
            transform: translateY(25px) scale(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-50px) scale(1);
            transform: translateY(-50px) scale(1); }
  80% {
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-250px);
            transform: translateY(-250px);
    opacity: 0; } }

.tech-cloud__cloud {
  position: absolute;
  width: 100%;
  z-index: 14; }

.tech-cloud__icon {
  position: absolute;
  width: 100%;
  z-index: 15;
  opacity: 0.5; }

@font-face {
  font-family: 'JMH Typewriter mono';
  src: url("/static/media/JMH Typewriter mono.592af56a.ttf");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'JMH Typewriter mono';
  src: url("/static/media/JMH Typewriter mono Bold.f9037945.ttf");
  font-weight: bold;
  font-style: normal; }

html {
  background-image: url(/static/media/background-darkgray.68c9a689.jpg);
  background-color: #282c34;
  background-repeat: repeat repeat;
  background-size: cover;
  overflow-x: hidden;
  height: 100%;
  scroll-behavior: smooth; }

::-webkit-scrollbar {
  width: 0.7rem; }

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.082);
  border-radius: 10px; }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.247); }

::-webkit-scrollbar-button,
::-webkit-scrollbar-corner,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  display: none; }

body, #root {
  height: 100%;
  position: relative; }
  body ::-moz-selection, #root ::-moz-selection {
    background: #313131;
    color: #c7c7c7; }
  body ::selection, #root ::selection {
    background: #313131;
    color: #c7c7c7; }

.App {
  position: relative; }

code {
  font-family: 'JMH Typewriter mono' !important; }

#main {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0; }

h2 {
  font-family: 'Cinzel', serif; }

.text-test {
  margin: 0;
  position: fixed;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
  font-family: 'Cormorant', serif; }


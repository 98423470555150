@import url('https://fonts.googleapis.com/css?family=Cinzel:700');
@font-face {
  font-family: 'JMH Typewriter mono';
  src: url('./assets/fonts/JMH Typewriter mono.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'JMH Typewriter mono';
  src: url('./assets/fonts/JMH Typewriter mono Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
// @font-face {
//   font-family: 'JMH Typewriter mono';
//   src: url('./assets/fonts/JMH Typewriter mono Italic.ttf');
//   font-weight: normal;
//   font-style: italic;
// }
// @font-face {
//   font-family: 'JMH Typewriter mono';
//   src: url('./assets/fonts/JMH Typewriter mono Fine.ttf');
//   font-weight: lighter;
//   font-style: normal;
// }

$background-url: url("./assets/background-darkgray.jpg");

html {
  background-image: $background-url;
  background-color: #282c34;
  background-repeat: repeat repeat;
  background-size: cover;
  overflow-x: hidden;
  height: 100%;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0.7rem;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.082); 
  border-radius: 10px;

  &:hover {
    background: rgba(255, 255, 255, 0.247);
  }
}

::-webkit-scrollbar-button, 
::-webkit-scrollbar-corner, 
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  display: none;
}

body, #root {
  height: 100%;
  position: relative;
  ::selection {
    background: rgb(49, 49, 49);
    color: rgb(199, 199, 199);
  }
}

.App {
  position: relative;
}

code {
  font-family: 'JMH Typewriter mono' !important;
}


#main {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
}

h2 {
  font-family: 'Cinzel', serif;
}

.text-test {
  margin: 0;
  position: fixed;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
  font-family: 'Cormorant', serif;
}